import React, { useState } from "react";
import { questions } from "./questions";
import SingleQuestion from "./SingleQuestion";

const Faqs = () => {
  const [cards] = useState(questions);

  return (
    <>
      <section className="max-w-xl mx-auto py-10 px-4">
        <h1 className="text-center  mx-auto font-semibold text3xl text-yellow-700 mb-8">
          FAQ's
        </h1>

        <section className="grid grid-cols-1 gap-5">
          {cards.map((card, index) => (
            <SingleQuestion {...card} key={index} />
          ))}
        </section>
      </section>
    </>
  );
};

export default Faqs;