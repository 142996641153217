// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-underline {
    position: relative;
  }
  
  .hover-underline::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -24px; /* Adjust this value to change the distance between text and underline */
    width: 100%;
    height: 2px;
    background-color: #d37126; /* Add your desired color here */
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .hover-underline:hover::after {
    transform: scaleX(1.5);
  }

a{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}

button{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;  
}

`, "",{"version":3,"sources":["webpack://./src/Styles/header.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,aAAa,EAAE,wEAAwE;IACvF,WAAW;IACX,WAAW;IACX,yBAAyB,EAAE,gCAAgC;IAC3D,oBAAoB;IACpB,+BAA+B;EACjC;;EAEA;IACE,sBAAsB;EACxB;;AAEF;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".hover-underline {\n    position: relative;\n  }\n  \n  .hover-underline::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    bottom: -24px; /* Adjust this value to change the distance between text and underline */\n    width: 100%;\n    height: 2px;\n    background-color: #d37126; /* Add your desired color here */\n    transform: scaleX(0);\n    transition: transform 0.3s ease;\n  }\n  \n  .hover-underline:hover::after {\n    transform: scaleX(1.5);\n  }\n\na{\n  font-family: 'Roboto';\n  font-style: normal;\n  font-weight: 700;\n  font-size: 15px;\n  line-height: 24px;\n}\n\nbutton{\n  font-family: 'Roboto';\n  font-style: normal;\n  font-weight: 700;\n  font-size: 15px;\n  line-height: 24px;  \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
