// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1{

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 43px;
color: black;
}

p{
    font-family: 'Poppins';
}
.outer{
    position:relative;
}
.inner-words{
    border: 0px solid #ddd;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    overflow: hidden;
}

.inner-words span{
    animation:animate 4s ease infinite;
    position:relative;
}

@keyframes animate{
    0% {
        top: 0;
    }
    16.66% {
        top: 0;
    }
    33.33% {
        top: -50px;
    }
    50% {
        top: -50px;
    }
    66.66% {
        top: -100px;
    }
    83.33% {
        top: -100px;
    }
    100% {
        top: 0;
    }
}

wrap{
    background: linear-gradient(93.25deg, rgba(255, 147, 86, 0.62) 2.15%, #8E3706 29.58%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

/* Simplify your AI journey, Go multicloud in minutes. */



`, "",{"version":3,"sources":["webpack://./src/Styles/hero.css"],"names":[],"mappings":"AAAA;;AAEA,qBAAqB;AACrB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,YAAY;AACZ;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,kCAAkC;IAClC,iBAAiB;AACrB;;AAEA;IACI;QACI,MAAM;IACV;IACA;QACI,MAAM;IACV;IACA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;IACA;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;IACA;QACI,MAAM;IACV;AACJ;;AAEA;IACI,qFAAqF;IACrF,6BAA6B;IAC7B,oCAAoC;IACpC,qBAAqB;IACrB,kCAAkC;AACtC;;AAEA,wDAAwD","sourcesContent":["h1{\n\nfont-family: 'Roboto';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 30px;\nline-height: 43px;\ncolor: black;\n}\n\np{\n    font-family: 'Poppins';\n}\n.outer{\n    position:relative;\n}\n.inner-words{\n    border: 0px solid #ddd;\n    height: 50px;\n    line-height: 50px;\n    text-transform: uppercase;\n    overflow: hidden;\n}\n\n.inner-words span{\n    animation:animate 4s ease infinite;\n    position:relative;\n}\n\n@keyframes animate{\n    0% {\n        top: 0;\n    }\n    16.66% {\n        top: 0;\n    }\n    33.33% {\n        top: -50px;\n    }\n    50% {\n        top: -50px;\n    }\n    66.66% {\n        top: -100px;\n    }\n    83.33% {\n        top: -100px;\n    }\n    100% {\n        top: 0;\n    }\n}\n\nwrap{\n    background: linear-gradient(93.25deg, rgba(255, 147, 86, 0.62) 2.15%, #8E3706 29.58%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-clip: text;\n    /* text-fill-color: transparent; */\n}\n\n/* Simplify your AI journey, Go multicloud in minutes. */\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
