// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capabilities-parent{
    background: rgba(255, 255, 255, 0.1);
}

  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: right;
  }
  
  .text-animation-container {
    flex: 1 1;
  }
  
  .capability-image {
    flex: 1 1;
    /* Adjust width as necessary */
    max-width: 80%;
  }`, "",{"version":3,"sources":["webpack://./src/Styles/capabilities.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;EAEE;IACE,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;EACpB;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,SAAO;IACP,8BAA8B;IAC9B,cAAc;EAChB","sourcesContent":[".capabilities-parent{\n    background: rgba(255, 255, 255, 0.1);\n}\n\n  .flex-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: right;\n  }\n  \n  .text-animation-container {\n    flex: 1;\n  }\n  \n  .capability-image {\n    flex: 1;\n    /* Adjust width as necessary */\n    max-width: 80%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
