import React, { useState } from 'react';
import BlogCard from '../Components/BlogCard';
import BlogPage from '../Components/BlogPage';
import blogsData from '../Components/blogs.json';


function BlogsPage() {
    const [selectedBlog, setSelectedBlog] = useState(null);

    const handleClick = (id) => {
        const blog = blogsData.find(blog => blog.id === id);
        setSelectedBlog(blog);
    };

    return (
        <div className="container mx-auto  py-8 mt-20 px-4">
            <div className="container mx-auto flex items-center justify-center text-center mb-10 mt-5 text-5xl font-bold font-sans text-yellow-700">
            Latest News & Updates
            </div>
            <div className="mt-5">
            {selectedBlog ? (
                <BlogPage blog={selectedBlog} />
            ) : (
                <div className="flex flex-col md:flex-row gap-10">
                    {blogsData.map(blog => (
                        <BlogCard key={blog.id} blog={blog} onClick={handleClick} />
                    ))}
                </div>
            )}
            </div>
            
        </div>
    );
}

export default BlogsPage;
