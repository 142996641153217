import React from 'react';
import Scale from "../Components/Scale"
import "../Styles/pricing.css"

const PricingCard = ({ plan }) => {
  return (
    <div className="border-2 flex flex-col py-6 px-8 rounded-lg gap-5 shadow-2xl bg-white hover:cursor-pointer card">
      <div className="text-3xl font-semibold heading">{plan.name}</div>
      <div className="text-6xl font-bold price">{plan.price}</div>
      <div className="text-xl text-zinc-400 usage">{plan.usage}</div>
      <div className=" price-btn">
        <button className="border-2 px-6 py-3 text-lg font-semibold text-yellow-700 border-yellow-600 hover:bg-yellow-600 hover:text-white">
          {plan.buttonText}
        </button>
      </div>
      <div className="border-b-4"></div>
      <div className="flex flex-col gap-2 plan-offers font-medium text-neutral-500">
        {plan.offers.map((offer, index) => (
          <p key={index}>{offer}</p>
        ))}
      </div>
    </div>
  );
};

const Pricing = () => {
  const plans = [
    {
      name: 'Starter Plan',
      price: '0$',
      usage: 'platform fees',
      buttonText: 'Start My 10-Days Trial',
      offers: [
        'Free 15-day trial',
        'Pay only for your cloud costs',
        'Bring your own AI assets',
        'One click model deployment',
        'Deploy on any cloud provider',
        'Zero upfront costs',
      ],
    },
    {
      name: 'Pro Plan',
      price: '$14',
      usage: 'per user, per month',
      buttonText: 'Start My 10-Days Trial',
      offers: [
        'Everything in Starter plan',
        'Fine-tune foundational models',
        'Observability & Monitoring on endpoints',
        'Basic Email Support',
      ],
    },
    {
      name: 'Enterprise Plan',
      price: '$40',
      usage: 'per use, per month',
      buttonText: 'Start My 10-Days Trial',
      offers: [
        'Everything in Starter + Pro plan',
        'Secure access of your models',
        'Secure migrations of models across clouds',
        'Enterprise Support with SLA',
        'Talk to us for custom pricing',
      ],
    },
  ];

  return (
    <div className='pricing-parent'>
      <div className="container mx-auto text-center text-3xl md:text-6xl font-bold font-serif mt-40 pricing-heading">
          Flexible Pricing Plans based on your needs
        </div>
        <div class="container mx-auto flex items-center flex-col md:flex-row justify-around gap-8 mb-5 md:mb-20 mt-5">
          <p class="flex text-black text-xs  md:text-2xl font-semibold items-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2.8"
              stroke="currentColor"
              class="w-6 h-6 text-orange-600 text-2xl font-bold"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            Free 15-day trial
          </p>
          <p class="flex text-black text-xs md:text-2xl font-semibold items-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2.8"
              stroke="currentColor"
              class="w-6 h-6 text-orange-600 text-2xl font-bold"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            Unlimited Team Members
          </p>
          <p class="flex text-center text-black text-xs md:text-2xl font-semibold items-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2.8"
              stroke="currentColor"
              class="w-6 h-6 text-orange-600 text-2xl font-bold"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
            Cancel Anytime
          </p>
        </div>
    <div className="container mx-auto flex flex-col items-center md:flex-row justify-around text-center gap-8 mt-20">
      {plans.map((plan, index) => (
        <PricingCard key={index} plan={plan} />
      ))}
    </div>
    <div className="-mb-20">
      {<Scale/>}
    </div>
    </div>
  );
};

export default Pricing;
