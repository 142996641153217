import React from "react";
import "../Styles/hero.css";
const Hero = () => {
  return (
    <div className="w-full  hero flex items-center justify-center h-[100vh] bg-white px-2">
      <div className="container mx-auto flex flex-col justify-between items-center md:flex-row">
        <div className="container flex flex-col items-center md:items-start gap-10 text-center md:text-left">
          <h1 className="container text-3xl md:text-7xl lg:text-8xl font-semibold">
            Start your <wrap>Multicloud AI</wrap>  journey in{" "}
            <wrap>minutes</wrap>
          </h1>
          <div className="flex justify-center items-center outer">
            <p className="md:text-2xl text-neutral-600 font-normal">
              Empower your AI journey{" "}
              <div className="inner-words">
                <span className="text-yellow-800 font-bold">
                  Train <br />
                  Deploy <br />
                  Scale <br />
                </span>
              </div>
              seamlessly on our all-in-one platform.
            </p>
          </div>

          <div className="hidden sm:flex gap-4">
            <a href="https://calendly.com/tejasnarayan">
              <button className="text-white hover:bg-yellow-600 hover:font-bold px-6 sm:px-10 py-4 rounded-md text-lg font-sembold bg-yellow-700">
              Book a Demo
              </button>
            </a>
            {/* <button className="text-white hover:bg-yellow-600 hover:font-bold px-6 sm:px-10 py-4 rounded-md text-lg font-sembold bg-yellow-700">
              Join Us
            </button> */}
          </div>
        </div>
        {/* <div className="flex items-center container">
          <img src={model} alt="" className="p-4 md:w-full" />
        </div> */}


       <iframe 
       title="EfficientAI"
      className="w-full aspect-video"
       src="https://www.loom.com/embed/1ae8d26d759347a687a91bfb533e700a?sid=4c8e5d8e-454f-4ed4-a250-908ccbf7d275" 
       style={{border: "0"}} 
       allow="fullscreen"> 
       </iframe>


        <div className="flex gap-4 mt-5 sm:hidden">
          <a href="https://mlb1u6ibtyr.typeform.com/to/EbeWdMXV">
          <button className="text-white hover:bg-yellow-600 hover:font-bold px-6 sm:px-10 py-4 rounded-md text-lg font-sembold bg-yellow-700">
            Join The Waitlist
          </button>
          </a>

        </div>
      </div>
    </div>
  );
};

export default Hero;
