import React from "react";
// import image1 from "../Assets/Rectangle 20.png";
// import image2 from "../Assets/Rectangle 21.png";
// import image3 from "../Assets/Rectangle 22.png";
import BlogsPage from "../Pages/BlogsPage";
// const blogs = [
//   {
//     id: 1,
//     image: image1,
//     title: "Why are we building EfficientAI?",
//     paragraph:
//       "The year 2023 witnessed rapid development in Generative AI and the launch of numerous Large Language Models (LLMs). This sparked a surge of startups and enterprises training and fine-tuning these LLMs for custom applications, decentralized AI, and even SaaS products. This trend is poised to explode in the coming decade.",
//     user: "path_to_user1.jpg",
//     username: "Tejas Narayan",
//     date: "03.04.2024",
//   },
//   {
//     id: 2,
//     image: image2,
//     title: "Why choose EfficientAI ?",
//     paragraph:
//       "Streamline Your AI Journey with EfficientAI's ML Control Plane as a Service We understand the complexities you face, as mentioned in our earlier blog Why we are building EfficientAI, when starting your AI journey, whether you're a startup or an established enterprise. EfficientAI is here to make it easier!",
//     user: "path_to_user1.jpg",
//     username: "Tejas Narayan",
//     date: "10.04.2024",
//   },
// ];
const HomeBlogs = () => {
  return (
    <div className="mb-5">
      <div className="container mx-auto flex justify-between px-4">
        <h3 className="text-5xl font-bold text-yellow-700">Blogs</h3>
        <a href="/blogs">
        <button className="rounded-full border-2 px-6 py-4 shadow-xl hover:bg-yellow-600 hover:text-white flex items-center text-center hover:shadow-2xl">
          See all blogs
        </button>
        </a>
      </div>
      {/* <div className="container mx-auto py-8">
        <div className="container mx-auto flex justify-center grid grid-cols-1 md:grid-cols-3 gap-10">
          {blogs.map((item) => (
            <Card
              key={item.id}
              image={item.image}
              title={item.title}
              paragraph={item.paragraph}
            />
          ))}
        </div>
      </div> */}
      <div className="container mx-auto flex justify-center -mt-20 mb-20 px-4">
        {<BlogsPage/>}
      </div>
    </div>
  );
};

export default HomeBlogs;
