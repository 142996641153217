import React from 'react'

const Scale = () => {
  return (
    <div className="px-4">
      <div className="container mx-auto items-center text-center  rounded-xl mt-20 bg-yellow-700 shadow-xl shadow-yellow-700 p-10">
          <h2 className="text-5xl font-bold text-white">
            Start for free. Scale without limits
          </h2>
          <div className="flex justify-center gap-10 mt-9">
            <a href="https://mlb1u6ibtyr.typeform.com/to/EbeWdMXV">
              {" "}
              <button className="px-6 py-3 bg-yellow-900 text-white hover:bg-white hover:text-black">
                Join The Wait List
              </button>
            </a>
          </div>
        </div>
    </div>
  )
}

export default Scale