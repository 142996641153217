import React from 'react';

const BlogPage = ({ blog }) => {
    return (
        <div className="max-w-4xl mx-auto mt-8">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <img src={blog.image} alt={blog.title} className="mx-auto object-cover object-center" style={{ maxHeight: '500px' }} />
                <div className="p-6">
                    <h2 className="text-2xl font-semibold mb-4">{blog.title}</h2>
                    {blog.paragraph.map((para, index) => {
                        if (para.includes(':')) {
                            const [highlight, text] = para.split(':');
                            return (
                                <p key={index} className="text-gray-700 blog-paragraph">
                                    <strong>{highlight}:</strong>{text}
                                </p>
                            );
                        }
                        return <p key={index} className="text-gray-700 blog-paragraph">{para}</p>
                    })}
                </div>
            </div>
            <a href="/" className='text-center'>Back To Home</a>
        </div>
    );
};

export default BlogPage;