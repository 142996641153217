import React from "react";
import AboutHero from "../Components/AboutHero";
import Teams from "../Components/Teams";
import Scale from "../Components/Scale";

const AboutPage = () => {
  return (
    <div className="mt-28">
      {<AboutHero/>}
      {<Teams/>}
      {<Scale/>}
    </div>
  );
};

export default AboutPage;
