import React from "react";
import Marquee from "react-fast-marquee";
import "../Styles/logoslider.css";
import img2 from "../Assets/Stability_AI_Logo-removebg-preview 1.png";
import img3 from "../Assets/geminiai.png";
import img4 from "../Assets/huggingface.png";
import img6 from "../Assets/meta.png";
import img7 from "../Assets/microsftai.png";
import img8 from "../Assets/mistralai.png";
import img9 from "../Assets/nvidia.png";
import img10 from "../Assets/openai.png";
import aws from "../Assets/aws.png";
import azure from "../Assets/azure.png";
import gcp from "../Assets/gcp.png";

const LogoSlider = () => {
  return (
    <div className="-mt-30 md:-mt-30 py-20 logo-parent">
      <h1 className="text-center mx-auto font-semibold text3xl text-yellow-700 md:text-5xl">
        Integrate with Prominent cloud providers
      </h1>
      <div className="w-full mt-5 md:mb-20 md:mt-14 px-3">
        <div className="container mx-auto flex items-center justify-around gap-3">
          {/* AWS Logo */}
          <div className="cursor-pointer shadow-2xl  rounded-md p-2 flex items-center justify-center">
            <img src={aws} alt="AWS Logo"  className="cloud-logo" />
          </div>
          <div className="bg-zinc-500 w-1 h-[12vh]"></div>
          {/* Azure Logo */}
          <div className="cursor-pointer shadow-2xl  rounded-md p-2 flex items-center justify-center">
            <img
              src={azure}
              alt="Azure Logo"
              className="cloud-logo"
            />
          </div>
          <div className="bg-zinc-500 w-1 h-[12vh]"></div>
          {/* GCP Logo */}
          <div className="cursor-pointer shadow-2xl  rounded-md p-2 flex items-center justify-center">
            <img src={gcp} alt="GCP Logo" className="gcp-logo" />
          </div>
        </div>
      </div>
      <h2 className="text-center text-1xl md:text-2xl font-sans w-full mx-auto font-medium cloud-provider-heading">
        Leverage Industry leading foundational models all in one place for your AI journey
      </h2>
      <div className="container mx-auto">
        <div className="md:mt-14">
          <Marquee direction="right" speed={100} delay={3}>
            <div className="image_wrapper">
              <img src={img2} alt="" className="w-1/2 h-auto" />
            </div>
            <div className="image_wrapper">
              <img src={img3} alt="" className="w-1/2 h-auto" />
            </div>
            <div className="image_wrapper">
              <img src={img4} alt="" className="w-1/2 h-auto" />
            </div>
            <div className="image_wrapper">
              <img src={img6} alt="" className="w-1/2 h-auto" />
            </div>
            <div className="image_wrapper">
              <img src={img7} alt="" className="w-1/2 h-auto" />
            </div>
            <div className="image_wrapper">
              <img src={img8} alt="" className="w-1/2 h-auto" />
            </div>
            <div className="image_wrapper">
              <img src={img9} alt="" className="w-1/2 h-auto" />
            </div>
            <div className="image_wrapper">
              <img src={img10} alt="" className="w-1/2 h-auto" />
            </div>

          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default LogoSlider;
