import React from "react";
// import icon1 from "../Assets/5665314_57829 1.png";
// import icon2 from "../Assets/Web development 1.png";
// import icon3 from "../Assets/Group 17863.png";
//import icon4 from "../Assets/Success 1.png";
//import icon4 from "../Assets/Success 1.png";
import "../Styles/capabilities.css";
import Lottie from "lottie-react";
import autoscalingAnimation from "../Assets/animations/autoscaling.json";
import multicloudAnimation from "../Assets/animations/multicloud.json";
import unified from "../Assets/animations/unified.json";
import finetuning from "../Assets/animations/finetune.json";

const capabilities = [
  {
    id: 1,
    title: "Efficient Auto-scaling",
    paragraph:
      "Automatically scale infrastructure for model deployments in real-time to optimize efficiency and cost based on demand.",
    animation: autoscalingAnimation,
  },
  {
    id: 2,
    title: "Efficient Model Fine-tuning",
    paragraph:
      "Refine model parameters for optimal accuracy and efficiency in line with evolving data and business demands.",
    animation: finetuning,  
  },
  {
    id: 3,
    title: "One Click Multi-Cloud Deployments",
    paragraph:
      "Deploy models across multiple cloud providers in minutes to leverage the best of each platform and avoid vendor lock-in.",
    animation: multicloudAnimation,  
  },
  {
    id: 4,
    title: "Unified Monitoring & Billing",
    paragraph:
      "Centralized monitoring,billing and observability of your multi-cloud model deployments for comprehensive control and streamlined operations.",
    animation: unified,  
  },

];
const Capabilities = () => {
  const Card = ({ animation, title, paragraph }) => {
    return (
      <div className="border-2 py-4 rounded-lg shadow-xl bg-white flex flex-col justify-between items-center md:flex-row hover:bg-yellow-500 hover:cursor-pointer">
        <div className="flex items-center flex-col">
          <h3 className="text-2xl md:text-4xl font-semibold mb-2 text-yellow-900 sm:hidden">
            {title}
          </h3>
          <Lottie
            animationData={animation}
            loop={true}
            autoplay={true}
            style={{ width: 200, height: 200, maxWidth: '90%', maxHeight: '90%' }}
          />
        </div>
        <div className="flex flex-col items-center text-center md:items-start md:text-left justify-center gap-2">
          <h3 className="text-2xl md:text-4xl font-semibold mb-2 text-yellow-900 hidden sm:block">
            {title}
          </h3>
          <p className="text-xl font-medium text-zinc-700 px-2">{paragraph}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="capabilities-parent mb-20 md:mb-40 px-4">
      <div className="container mx-auto">
        <div className="text-3xl md:text-5xl font-bold text-yellow-700 text-center mb-10">
          Capabilities
        </div>
        <div className="">
          <div className="container mx-auto py-8 md:py-12 flex items-center justify-center">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
              {capabilities.map((item) => (
                <Card
                  key={item.id}
                  animation={item.animation}
                  title={item.title}
                  paragraph={item.paragraph}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Capabilities;
