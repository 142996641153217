import React from 'react';
import Slider from 'react-slick';

const Carousel = ({ reviews }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 0,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div>
        <div className="text-3xl md:text-5xl font-bold text-yellow-700 text-center mb-10">
          Trusted by Enterprise Leaders
        </div>
        <Slider {...settings}>
            {reviews.map((review, index) => (
                 <div key={index} className="carousel-item">
                 <div className="review-box">
                 <div className="avatar-name-container">
                 <img src={review.avatar} alt={review.name} className="avatar" />
                    <div>
                         <h3 className="name">{review.name}</h3>
                         <p className="position">{review.position}</p>
                     </div>
                     </div>
                     <p className="review">{review.text}</p>
                 </div>
             </div>
            ))}       
        </Slider>
        </div>
    );
};

export default Carousel;