import React from "react";
import aboutbanner from "../Assets/who_we_are.png"

const AboutHero = () => {
  return (
    <div>
      <div className="container mx-auto flex flex-col justify-between items-center md:flex-row">
        <div className="container flex flex-col items-center md:items-start gap-10 text-center md:text-justify">
          <h2 className="text-4xl font-bold">Who <wrap>we are....</wrap> </h2>
          <p className="text-lg font-medium text-zinc-600 px-3">
            We are a team of passionate technologists who have experience in building enterprise-grade platforms and products.
          </p>
          <div className="container w-full md:hidden">
            <img className="w-1/2 h-auto ml-20" src={aboutbanner} alt="" />
        </div>
          <p className="text-lg font-medium text-zinc-600 px-3">Having worked in multiple big tech companies including AWS, Microsoft, Citrix, & Flipkart,
          we come with a wide range of experience building large scale distributed systems on top of cloud providers</p>
        </div>
        <div className="container w-full hidden md:block">
            <img className="w-1/2 h-auto ml-20" src={aboutbanner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutHero;