import React from 'react'
import tejaspic from "../Assets/avatars/tejas_image.jpeg"
import srivatspic from "../Assets/avatars/srivatsa_image.jpeg"
import linkdin from "../Assets/Component 8.png"
// import twitter from "../Assets/Component 9.png"
const team = [
  {
    id: 1,
    image: tejaspic,
    title: "Founder",
    name: "Tejas Narayan",
    linkedin: "https://www.linkedin.com/in/tejasnarayans/",
    twitter: "path",
  },
  {
    id: 2,
    image: srivatspic,
    title: "Co-Founder",
    name: "Srivatsa Sinha",
    linkedin: "https://www.linkedin.com/in/srivatsa-sinha-631ba9b9/",
    twitter: "path",
  },
  ];

const Teams = () => {
  const Card = ({ image, title, name, linkedin, twitter }) => {
    return (
      <div className="flex flex-col  text-center">
        <img src={image} alt={title} className="w-24 h-24 rounded-full object-cover mx-auto mb-10" />
        <div className="flex flex-col items-center px-2">
        <h3 className="text-2xl font-semibold text-zinc-500">{title}</h3>
        <p className="text-4xl font-medium mb-8 mt-5">{name}</p>
        {linkedin && (
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <img src={linkdin} alt="" className='h-auto' />
          </a>
        )}
        {twitter && (
          <a href={twitter} target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="" className='h-auto mb-5'/>
          </a>
        )}
        </div>
      </div>
    );
  };
  return (
    <div className='mt-10 mb-5 px-5'>
        <div className="container mx-auto flex flex-col justify-center items-center gap-8">
            {/* <h4>Teams Section</h4> */}
            <h2 className='text-4xl font-bold text-orange-800'>Meet the Team</h2>
            {/* <p className='text-3xl font-medium text-zinc-600 text-center'>Meet our team of professionals to serve you </p> */}
            <div className="container mx-auto py-8">
        <div className="flex flex-col md:flex-row items-center gap-10 md:gap-40 items-center justify-center">
          {team.map((item) => (
            <Card
              key={item.id}
              image={item.image}
              title={item.title}
              name={item.name}
              linkedin={item.linkedin}
              twitter={item.twitter}
            />
          ))}
        </div>
      </div>
        </div>
    </div>
  )
}

export default Teams