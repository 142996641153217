import abhishek from '../Assets/avatars/abhishek.png';
import gururaj from '../Assets/avatars/gururaj.jpeg';
import ipsita from '../Assets/avatars/ipsita.jpeg';
import salman from '../Assets/avatars/salman.jpeg';

export const reviews = [
    {
        name: 'Abhishek Kumar Gupta',
        position: 'Senior Staff Engineer @ Nvidia',
        text: 'EfficientAI is great place to start deploying models quickly into production in a multi-cloud environment. It is a great tool for data scientists and machine learning engineers to securely deploy models in a few clicks.',
        avatar: abhishek, // Replace with actual image paths
    },
    {
        name: 'Gururaj Nagaraj Rao',
        position: 'Principal Engineer @ Walmart',
        text: 'Efficient AI cloud platform definitely simplifies the multi-cloud management of deploying and operating LLM Models. It was impressive to see the demo of different capabilities this platform supports which would  reduce the turnaround time to build and operate GenAI applications',
        avatar: gururaj, // Replace with actual image paths
    },
    {
        name: 'Ipsita Bhattacharya',
        position: 'Senior Director @ NASDAQ',
        text: 'This platform would revolutionize various workflows by enabling seamless deployment of multiple LLM models across various cloud providers. With the advent of AI and its uptick in usage across various industry sectors,this surely hits the right set of challenges that the end user would have to navigate through eventually ( for instance, multi cloud multi model scalability metrics).',
        avatar: ipsita, // Replace with actual image paths
    },
    {
        name: 'Salman Bhatti',
        position: 'VP of Engineering @ Vertex',
        text: 'Good place to get started with AI needs and it definitely simplifies the developers user journey for tinkering around  with a very minimal learning curve.',
        avatar: salman, // Replace with actual image paths
    }
    
];