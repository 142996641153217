// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Assets/Looper BG (1).png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Manage All Your Cloud Profiles From One Place. */
.pricing-heading{
font-family: 'Montserrat';
font-style: normal;
font-weight: 900;
text-align: center;
color: #000000;
}


.pricing-parent {
    position: relative;
}

.pricing-parent::before {
    content: "";
    position: absolute;
    width: 2259.73px;
    height: 1396.13px;
    left: -692.65px;
    top: 4645px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: rotate(15deg);
    z-index: -1;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/pricing.css"],"names":[],"mappings":"AAAA,mDAAmD;AACnD;AACA,yBAAyB;AACzB,kBAAkB;AAClB,gBAAgB;AAChB,kBAAkB;AAClB,cAAc;AACd;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,yDAAwD;IACxD,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,wBAAwB;IACxB,WAAW;AACf","sourcesContent":["/* Manage All Your Cloud Profiles From One Place. */\n.pricing-heading{\nfont-family: 'Montserrat';\nfont-style: normal;\nfont-weight: 900;\ntext-align: center;\ncolor: #000000;\n}\n\n\n.pricing-parent {\n    position: relative;\n}\n\n.pricing-parent::before {\n    content: \"\";\n    position: absolute;\n    width: 2259.73px;\n    height: 1396.13px;\n    left: -692.65px;\n    top: 4645px;\n    background-image: url(\"../Assets/Looper\\ BG\\ \\(1\\).png\");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    transform: rotate(15deg);\n    z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
