import React from "react";
import Pricing from "../Components/Pricing";

const PricingPage = () => {
  return (
    <div className="flex flex-col justify-center items-center mx-auto">
      {<Pricing/>}
    </div>
  );
};

export default PricingPage;
