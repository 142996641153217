// src/components/BlogCard.js
import React from 'react';

const BlogCard = ({ blog, onClick }) => {
    return (
        <div className="container mx-auto flex flex-col items-center mt-10 mb-5 text-center bg-white shadow-lg rounded-md px-2 overflow-hidden gap-5 py-4">
            {/* <img src={blog.image} alt={blog.title} className="h-auto" /> */}
            <div className="">
                <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
                <p className="text-gray-700 line-clamp-2">{blog.paragraph}</p>
                <button className="mt-4 bg-yellow-700 hover:bg-yellow-500 text-white py-2 px-4 rounded" onClick={() => onClick(blog.id)}>Read More</button>
            </div>
        </div>
    );
};

export default BlogCard;
