import React from "react";
import logo from "../Assets/efficientailogo.png";
import linkdin from "../Assets/Component 8.png";
import twitter from "../Assets/Component 9.png";
import gmail from "../Assets/Vector (2).png";
import gcpStartups from "../Assets/gcp_startups.png";
import awsStartups from "../Assets/aws_startups.png";

const Footer = () => {
  return (
    <div className="w-full mt-40 px-4">
      <div className="container mx-auto flex-col md:flex-row flex items-center justify-between">
        <div className="flex items-center">
          <a href="/" className="flex items-center justify-center gap-3">
            <img src={logo} alt="" className="w-10 h-auto md:w-10 lg:w-20" />
            <span className="font-bold text-gray-500 text-2xl md:text-3xl lg:text-4xl">
              Efficient<wrap className="text-yellow-800">AI</wrap>
            </span>
          </a>
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <a href="/" className="flex items-center justify-center gap-3">
            <img src={gcpStartups} alt="" className="w-24 h-auto md:w-24 lg:w-28 mb-4 md:mb-0" />
            <img src={awsStartups} alt="" className="w-24 h-auto md:w-24 lg:w-28" />
          </a>
        </div>
        <div className="flex gap-10 font-medium text-2xl text-orange-600 ">
          <a href="https://www.linkedin.com/company/efficientaicloud" target="_blank" rel="noopener noreferrer">
            <img src={linkdin} alt="" />{" "}
          </a>
          <a href="https://x.com/AiEfficient" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="" />{" "}
          </a>

          <a href="mailto:contact@efficientai.cloud" target="_blank" rel="noopener noreferrer">
            {" "}
            <img src={gmail} alt="" />{" "}
          </a>
        </div>
      </div>
      <div className="mx-auto border-b-4 border-gray-400 mt-10 w-full"></div>
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between my-4">
        <p className="text-2xl text-left">
          © 2024 Copyright. EfficientAI.cloud
        </p>
        <p className="text-1xl text-right">
          contact@efficientai.cloud
        </p>
      </div>
    </div>
  );
};

export default Footer;
